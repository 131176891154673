import { Loader } from "design-system";
import { useEffect } from "react";
import { useCommand } from "../useAPI";
import * as api from "./api";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";

type Props = {
  onContinue: () => unknown;
  onError: () => unknown;
  applicantIndex?: string;
};

export function DigitalIdStart({ onContinue, onError, applicantIndex }: Props) {
  const start = useCommand(api.digitalIdStart);

  useEffect(() => {
    pipe(
      start({ applicantIndex }),
      taskEither.fold(
        _ => taskEither.fromIO(onError),
        _ => taskEither.fromIO(onContinue)
      )
    )();
  }, []);

  return (
    <>
      <Loader />
    </>
  );
}
