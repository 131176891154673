import { option, taskEither } from "fp-ts";
import {
  Box,
  FormErrors,
  LoadingButton,
  Space,
  TextField,
  unsafeLocalizedString,
} from "design-system";
import { useFormatMessage } from "../intl";
import { useState } from "react";
import { useCommand } from "../useAPI";
import { pipe } from "fp-ts/function";
import { digitalIdSetBirthNumber } from "./api";

type Props = {};

export function DigitalIdClientMock(_: Props) {
  const formatMessage = useFormatMessage();
  const [birthNumber, setBirthNumber] = useState<string>("");
  const [error, setError] = useState<string>();
  const setCustomerBirthNumber = useCommand(digitalIdSetBirthNumber);

  const handleSubmit = birthNumber
    ? pipe(
        setCustomerBirthNumber({ birthNumber: birthNumber }),
        taskEither.fold(
          () => taskEither.fromIO(() => setError("Invalid birth number")),
          () => taskEither.fromIO(() => {})
        )
      )
    : taskEither.left(() => {});

  return (
    <>
      <Space units={4} />
      <Box hAlignContent={"right"}>
        <Box>
          <TextField
            name="birthNumber"
            onChange={v => {
              setError(undefined);
              setBirthNumber(v);
            }}
            value={birthNumber}
            label={unsafeLocalizedString("")}
            placeholder={formatMessage(
              "Identification.personalData.birthNumber"
            )}
            issues={option.none}
            onBlur={() => {}}
          />
        </Box>
        <Space units={4} />
        <Box vAlignContent={"bottom"}>
          <LoadingButton
            variant="primary"
            size="default"
            labels={{
              success: unsafeLocalizedString("Set Birth Number"),
              error: unsafeLocalizedString("Set Birth Number"),
              loading: unsafeLocalizedString("Set Birth Number"),
              normal: unsafeLocalizedString("Set Birth Number"),
            }}
            action={handleSubmit}
            disabled={!birthNumber}
          />
        </Box>
      </Box>
      {error ? (
        <>
          <Space units={4} />
          <Box hAlignContent={"right"}>
            <FormErrors errors={option.some([unsafeLocalizedString(error)])} />
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
