import { option, taskEither } from "fp-ts";
import { DigitalIdStart } from "./DigitalIdStart";
import { useState } from "react";
import { DigitalIdClientProfile } from "./DigitalIdClientProfile";
import { DigitalIdStatus } from "./DigitalIdStatus";
import { DigitalIdStop } from "./DigitalIdStop";
import { FeedbackBlock } from "design-system";
import { useFormatMessage } from "../intl";
import { useAppContext } from "../useAppContext";
import { DigitalIdClientMock } from "./DigitalIdClientMock";

type Props = {
  onClientIdentified: () => unknown;
  applicantIndex?: string;
};

type State = "error" | "start" | "status" | "stop" | "clientProfile";

export function DigitalId({ onClientIdentified, applicantIndex }: Props) {
  const formatMessage = useFormatMessage();
  const [state, setState] = useState<State>("start");
  const {
    config: { mockScanner },
  } = useAppContext();

  return (
    <>
      {state === "error" && (
        <FeedbackBlock
          type={"negative"}
          heading={formatMessage("GenericError")}
          size={"medium"}
          subheading={option.none}
          actions={[]}
        />
      )}
      {state === "start" && (
        <DigitalIdStart
          onContinue={() => setState("status")}
          onError={() => setState("error")}
          applicantIndex={applicantIndex}
        />
      )}
      {state === "status" && (
        <DigitalIdStatus
          onContinue={() => setState("clientProfile")}
          onStop={() => setState("stop")}
        />
      )}
      {state === "stop" && (
        <DigitalIdStop onRetry={taskEither.fromIO(() => setState("start"))} />
      )}
      {state === "clientProfile" && (
        <>
          <DigitalIdClientProfile onClientIdentified={onClientIdentified} />
          {mockScanner && <DigitalIdClientMock />}
        </>
      )}
    </>
  );
}
