import { apiCall } from "../APICall";
import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { ClientDataOutput } from "../IdUpload/api";

export const digitalIdStart = apiCall({
  path: ["clients", "digitalId", "start"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.type({
    applicantIndex: t.union([t.string, t.null, t.undefined]),
  }),
  outputCodec: t.union([t.void, t.null, t.undefined]),
});

const DigitalIdStatus = t.type({
  status: t.union([
    t.literal("RETRY"),
    t.literal("STOP"),
    t.literal("CONTINUE"),
  ]),
  stopReason: t.string,
});
export const digitalIdStatus = apiCall({
  path: ["clients", "digitalId", "status"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: DigitalIdStatus,
});

const DigitalIdClientIdentified = t.type({
  photo: t.string,
});
export type DigitalIdClientIdentified = t.TypeOf<
  typeof DigitalIdClientIdentified
>;
export const digitalIdClientIdentified = apiCall({
  path: ["clients", "digitalId", "client", "photo"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: DigitalIdClientIdentified,
});

const DigitalIdQrCode = t.type({
  qrCode: t.string,
});
export const digitalIdQrCode = apiCall({
  path: ["clients", "digitalId", "qrCode"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: DigitalIdQrCode,
});

const DigitalIdClientCheckExistingResponse = t.type({
  existingClient: t.boolean,
  duplicateContacts: t.boolean,
  hasBlockingNotes: t.boolean,
  multipleUsers: t.boolean,
  personalNumberMatch: t.boolean,
  clientNumber: t.union([t.string, t.null, t.undefined]),
  clientStatus: t.union([t.string, t.null, t.undefined]),
  userID: t.union([t.string, t.null, t.undefined]),
});
export type ExistingClient = t.TypeOf<
  typeof DigitalIdClientCheckExistingResponse
>;
export const digitalIdClientCheckExisting = apiCall({
  path: ["clients", "digitalId", "client", "checkExisting"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: DigitalIdClientCheckExistingResponse,
});

export const digitalIdExtractData = apiCall({
  path: ["clients", "digitalId", "extractData"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: ClientDataOutput,
});

const DigitalIdSavedDocuments = t.type({
  ready: t.boolean,
  documents: t.array(
    t.type({
      name: t.string,
      id: t.string,
    })
  ),
});

export type SavedDocuments = t.TypeOf<typeof DigitalIdSavedDocuments>;

export const digitalIdSaveDocuments = apiCall({
  path: ["clients", "digitalId", "identification", "documents", "save"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: t.union([t.void, t.null, t.undefined]),
});

const FlowInfo = t.type({
  info: t.string,
});

export const digitalIdFlowInfo = apiCall({
  path: ["clients", "digitalId", "identification", "flowInfo"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: FlowInfo,
});

export const digitalIdGetDocuments = apiCall({
  path: ["clients", "digitalId", "identification", "documents", "get"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: DigitalIdSavedDocuments,
});

export const digitalIdSetBirthNumber = apiCall({
  path: ["clients", "digitalId", "client", "birthNumber"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.type({ birthNumber: t.string }),
  outputCodec: t.union([t.void, t.null, t.undefined]),
});
